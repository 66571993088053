import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import logo from '../../img/logo_header.png';
import './Navbar.scss'

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mobileMenuVisible: false };
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  drawNavLinks(navLinks) {
    const links = navLinks.map((link) => {
      if (link.wordpress_children) {

        let hasActiveChild;

        if (typeof window !== 'undefined' && window.location) {
          hasActiveChild = link.wordpress_children.find(c => `/${c.object_slug}` === window.location.pathname);
        }

        return (
          <div
            className={`navbar-item has-dropdown is-hoverable ${hasActiveChild ? 'active': ''}`}
            key={link.wordpress_id}
          >
            <Link
              className='navbar-link is-arrowless'
              to={link.object_slug}
              dangerouslySetInnerHTML={{ __html: link.title }}
            >
            </Link>
            <div
              className='navbar-dropdown'
            >
            {this.drawNavLinks(link.wordpress_children)}
            </div>
          </div>
        )
      } else {
        let to;
        if (link.object_slug === 'home') {
          to = '/';
        } else if (link.object_slug === 'contact-us') {
          to = '/contact';
        } else {
          to = link.object_slug;
        }

        return (
          <Link
            className='navbar-item'
            activeClassName='active'
            to={to}
            key={link.wordpress_id}
            dangerouslySetInnerHTML={{ __html: link.title }}
          >
          </Link>
        )
      }
    });

    return links

  }

  toggleMobileMenu() {
    this.setState(state => ({
      mobileMenuVisible: !state.mobileMenuVisible
    }));
  }

  render() {

    return (
      <StaticQuery
        query={graphql`
          query MenuQuery {
            site {
              siteMetadata {
                title
              }
            }
            allWordpressWpApiMenusMenusItems {
              edges {
                node {
                  name
                  slug
                  count
                  items {
                    order
                    wordpress_id
                    title
                    object_slug
                    wordpress_children {
                      wordpress_id
                      title
                      object_slug
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div className="container">
            <nav className="navbar">
              <div className="navbar-brand">
                <Link to="/" className="navbar-item">
                  <img src={logo} alt={data.site.siteMetadata.title + ' logo'} />
                </Link>
                <a
                  role="button"
                  className={`navbar-burger burger ${this.state.mobileMenuVisible ? 'is-active' : ''}`}
                  aria-label="menu"
                  aria-expanded="false"
                  data-target="navbarBasicExample"
                  onClick={this.toggleMobileMenu}
                >
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </a>
              </div>
              <div className={`navbar-menu ${this.state.mobileMenuVisible ? 'is-active' : ''}`}>
                <div className="navbar-end">
                  {this.drawNavLinks(data.allWordpressWpApiMenusMenusItems.edges.find(e => e.node.slug === 'primary-navigation').node.items)}
                </div>
              </div>
            </nav>
          </div>
        )}
      />
    )

  }
}

export default Navbar
