import React from 'react'
import Helmet from 'react-helmet'

import Navbar from './Navbar/Navbar'
import Footer from './Footer/Footer'
import '../styles/all.scss'

const TemplateWrapper = ({ children }) => (
  <div className="site-wrapper">
    <Helmet title="Home | Woods International" />
    <Navbar />
    <div className="site-content">{children}</div>
    <Footer />
  </div>
)

export default TemplateWrapper
