import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { FaTwitter, FaFacebook, FaLinkedin } from 'react-icons/fa'
import logo from '../../img/logo_footer.png'

import './Footer.scss'

class Footer extends React.Component {
  drawNavLinks(navLinks) {
    const links = navLinks.map(link => (
      <li key={link.wordpress_id}>
        <Link to={link.object_slug === 'home' ? '/' : link.object_slug}>
          <span dangerouslySetInnerHTML={{ __html: link.title }} />
        </Link>
      </li>
      ))

    return <ul className="footer-links">{links}</ul>
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query FooterQuery {
            site {
              siteMetadata {
                title
              }
            }
            allWordpressWpApiMenusMenusItems {
              edges {
                node {
                  name
                  slug
                  count
                  items {
                    order
                    wordpress_id
                    title
                    object_slug
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div className="footer-wrapper">
            <div className="container footer-main has-text-centered-mobile">
              <div className="columns">
                <div className="column is-6">
                  <h4>{data.site.siteMetadata.title}</h4>
                  <p>
                    Unit U & X Daneholes Roundabout
                    <br />
                    Stanford Road
                    <br />
                    Grays
                    <br />
                    RM16 2XE
                  </p>
                  <p>
                    Office: +44 (0) 20 3198 4049
                    <br />
                    Email: sales@woodsinternational.co.uk
                  </p>
                  <div className="social-icons">
                    <a
                      href="https://twitter.com/WoodsInternati1"
                      target="_blank"
                    >
                      <FaTwitter />
                    </a>
                    <a
                      href="https://www.facebook.com/Woods-International-297829177551572"
                      target="_blank"
                    >
                      <FaFacebook />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/woods-international-design-specialists/"
                      target="_blank"
                    >
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
                <div className="column is-6 has-text-right has-text-centered-mobile footer-logo-wrapper">
                  <img
                    src={logo}
                    alt={`${data.site.siteMetadata.title  } logo`}
                  />
                  <p>
                    Spoom Limited trading as Woods International.
                    <br />
                    Registered in England No 9991023.
                    <br />
                    <a href="https://www.dhdesigns.uk" target="_blank">
                      Website by DH Designs
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="footer-menu">
              <div className="container">
                {this.drawNavLinks(
                  data.allWordpressWpApiMenusMenusItems.edges.find(
                    e => e.node.slug === 'footer-menu'
                  ).node.items
                )}
              </div>
            </div>
          </div>
        )}
      />
    )
  }
}

export default Footer
